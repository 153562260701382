import React from "react";
import { TiPhoneOutline } from "react-icons/ti";
import { TfiEmail } from "react-icons/tfi";
import { BsLinkedin, BsTwitter } from "react-icons/bs";


const Contact = (props) => {

    return (
        <div id="Contact"  className="p-8 bg-gradient-to-bl from-aqua-dark to-aqua-medium">
            <div className="flex justify-center align-items-center px-8">
                <a href="https://twitter.com/Ryan_O_Lee" target="_blank" rel="noreferrer" className="justify-center align-items-center flex-row px-4">
                    <BsTwitter className="text-white inline-flex mr-4 hover:text-blue-400 hover:cursor-pointer" />
                </a>
                <div className="justify-center align-items-center flex-row px-4">
                    <TfiEmail className="text-white inline-flex mr-4" />
                    <span className="text-white" >ryanolee11@gmail.com</span>
                </div>
                <div className="justify-center align-items-center flex-row px-4">
                    <TiPhoneOutline className="text-white inline-flex mr-4" />
                    <span className="text-white" >518-570-9021</span>
                </div>
                <a href="https://www.linkedin.com/in/ryan-lee-a50262139" target="_blank" rel="noreferrer" className="justify-center align-items-center flex-row px-4">
                    <BsLinkedin className="text-white inline-flex mr-4 hover:text-blue-600 hover:cursor-pointer" />
                </a>
            </div>
        </div>
    );
}


export default Contact;