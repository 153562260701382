import './App.css';
import NavBar from './Components/NavBar';
import About from './Components/About';
import Contact from './Components/Contact';
import Work from './Components/Work';
import Skills from './Components/Skills';
import { Fragment } from 'react';

function App() {
  return (
    <Fragment>
      <NavBar />
      <div className='pt-16 bg-aqua-dark'></div>
      <About />
      <Work />
      <Skills />
      <Contact />
    </Fragment>
  );
}

export default App;
