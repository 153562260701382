import React from "react";
import ProjectCard from "./ProjectCard";
import Projects from "../Constants/Projects";

const Work = (props) => {

    return (
        <div id="Work" className={`sm:p-16 p-4  bg-contour-lines bg-right-top bg-cover`}>
            <div className="flex xl:justify-around xl:w-full xl:flex-row xl:mx-0 xl:gap-y-0 pb-12 flex-col sm:w-96 w-80 mx-auto gap-y-20 ">
                {Projects.map(project => (<ProjectCard tags={project.tags} title={project.title} description={project.description} url={project.url} thumbnail={project.thumbnail} />))}
            </div>
        </div>
    );
}


export default Work;