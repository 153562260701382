import React from "react";
import man from '../Assets/img/ryan_headshot.png';
import blobProfile from '../Assets/img/blobProfile.svg';

const About = (props) => {

    return (
        <div id="About" className="sm:p-16 p-4 bg-gradient-to-bl from-aqua-dark to-aqua-medium">
            <div className="flex sm:justify-around align-center flex-col sm:flex-row justify-center m-auto">
                <div className="sm:w-96 w-80 sm:h-96 h-80 justify-center items-center relative m-auto">
                    <img alt="profile blob" className="realtive" src={blobProfile}/>
                    <img alt="profile pic" className="w-[45%] absolute top-[18%] left-[33%] rounded-full" src={man} />
                </div>
                <div className="sm:px-12 sm:w-1/2 w-3/4 max-h-96 h-96 flex items-center justify-self-center m-auto overflow-hidden rounded	">
                    <div className="text-white" >
                        Welcome to my portfolio website! My name is Ryan, a software engineer with over 5 years of experience, including my recent role as a senior member at <a href="ngena.net" className="text-purple-400 underline">ngena</a>. I'm also a proud father of two. In my free time, I enjoy exploring new technology, playing video games, and participating in sports.
                    </div>
                </div>
            </div>
        </div>
    );
}


export default About;