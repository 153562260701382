
import React from "react";

const ProjectCard = (props) => {

    const TechnologyPill = ({title}) => {
        let techBackgrounds = {
            "Web3": "bg-purple-700",
            "React": "bg-sky-400",
            "Solidity": "bg-gray-700",
            "JavaScript": "bg-yellow-500",
            "Oracle APEX": "bg-blue-700",
            "PL/SQL": "bg-red-400",
            "Less": "bg-teal-200",
            "Jquery": "bg-yellow-500"
        }

        return (
            <div className={`py-1 px-2 ${techBackgrounds[title]} rounded-full text-white`}>{title}</div>
        );
    };

    return (
        <a className="h-96 mb-12 hover:pointer hover:shadow-2xl hover:shadow-orange-dark lg:mb-0 mb-8" href={props.url} rel="noreferrer" target="_blank">
            <article className="card_wrapper sm:w-96 w-80 realtive h-80 border-solid border-black rounded-md flex flex-col bg-gradient-to-bl from-aqua-dark to-aqua-medium ">
                <img alt="thumbnail for project" src={props.thumbnail} className="h-80 rounded-md min-h-[14rem]"/>
                <div className="project_summary relative px-5 flex-1 rounded-md bg-white pb-4">
                    <h2 className="project_title pt-2 text-xl">{props.title}</h2>
                    <div className="project_tags flex flex-1 flex col gap-1 my-2">
                        {props.tags.map(tag => (<TechnologyPill title={tag} />))}
                    </div>
                    <p className="project_desc w-full text-sm text-slate-700">{props.description}</p>
                </div>
            </article>
        </a>
    );
}


export default ProjectCard;


/*
a
  text-decoration: none
  color: inherit !important
  // border: 1px solid red
  height: 425px
  margin-bottom: 50px
  
.wrapper
  max-width: 850px
  margin: 20px auto
  height: auto
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-around
*/