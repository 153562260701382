import React from "react";

const Skills = (props) => {

    const frontendSkills = ['JavaScript', 'React', 'Jquery', 'LESS', 'CSS3', 'Oracle APEX', 'Tailwind CSS'];
    const backendSkills = ['Oracle Database', 'MySQL', 'Python', 'MongoDB', 'Solidity', 'PL/SQL'];

    return (
        <div id="Skills" className="sm:p-16 p-4 bg-gradient-to-bl from-aqua-dark to-aqua-medium">
            <div className="skills_wrapper flex flex-col sm:flex-row gap-x-12 gap-y-12 justify-center">
                <div className="frontend_skills flex flex-col bg-orange-hazy rounded-xl sm:p-6 p-2 lg:p-16 sm:w-96 w-80 m-auto">
                    <div className="text-center text-white pb-8 text-4xl">Frontend</div>
                    <div className="flex flex-wrap gap-y-2 gap-x-2">{frontendSkills.map((skill) => {
                        return (<div className="p-2 bg-gradient-to-bl from-aqua-light to-aqua-dark border-slate rounded-full text-white">{skill}</div>)
                    })}</div>
                </div>
                <div className="backend_skills flex flex-col bg-orange-hazy rounded-xl sm:p-6 p-2 lg:p-16 sm:w-96 w-80 m-auto">
                    <div className="text-center text-white pb-8 text-4xl">Backend</div>
                    <div className="flex flex-wrap gap-y-2 gap-x-2">{backendSkills.map((skill) => {
                        return (<div className="p-2 bg-gradient-to-bl from-aqua-light to-aqua-dark border-slate rounded-full text-white">{skill}</div>)
                    })}</div>
                </div>
            </div>
        </div>
    );
}


export default Skills;