import  kryptThumbnail  from '../Assets/img/krypt.png'
import  fundmeThumbnail  from '../Assets/img/fundme.png'
import  quicksizerThumbnail  from '../Assets/img/quicksizer.png'

const Projects = [
    {
        "title": "Krypt",
        "description": "This project will allow you to send crypto from one wallet to another.Once sent it will generate a GIF according to the message.You can see the transaction/GIF history.",
        "url": "https://ryanolee11.github.io/nft_transaction_exchange",
        "thumbnail": kryptThumbnail,
        "tags": ["Web3", "React", "Solidity"]
    },
    {
        "title": "Fundm3",
        "description": "This is a crowdfunding application built in web3. I created a contract on ethereum to create and update funding campaigns.Feel free to add your own campaign.",
        "url": "https://ryanolee11.github.io/thirdweb-app",
        "thumbnail": fundmeThumbnail,
        "tags": ["Web3", "React", "Solidity"]
    },
    {
        "title": "QuickSizer",
        "description": "This is a customer facing application I worked on at ngena.This tool allows us to quote your desired network effeciently. This is best viewed on a desktop or a large mobile device",
        "url": "https://quicksizer.net",
        "thumbnail": quicksizerThumbnail,
        "tags": ["Oracle APEX", "PL/SQL", "Less", "Jquery"]
    }
]

export default Projects