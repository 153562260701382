import React, { useState } from "react";
import {HiMenuAlt4, HiX} from 'react-icons/hi';
import ryanLogo from '../Assets/img/ryanLogo.svg';
import { motion } from 'framer-motion';


const NavBar = (props) => {

    const [menuToggled, setMenuToggle] = useState(false); 

    const toggleMenu = () => {
        setMenuToggle(!menuToggled);
    }

    return (<nav className="app__navbar w-full flex justify-between items-center py-4 px-8 bg-aqua-dark fixed z-10">
        <div className="app__navbar-logo flex justify-start items-center">
            <img alt="logo" src={ryanLogo} className="h-12" />
        </div>
        <ul className="app__navbar-links lg:flex hidden flex-1 justify-center items-center list-none">
            {['About', 'Work', 'Skills', 'Contact'].map((item) => (
                <li  className="my-0 mx-4 flex-col group justify-center" key={`link-${item}`}>
                    <div className=" w-1.5 h-1.5 bg-transparent mb-5px mx-auto rounded-full group-hover:bg-orange-light" />
                    <a className="text-gray-400 no-underline uppercase font-medium  group-hover:text-orange-light" href={`#${item}`}>
                        {item}
                    </a>
                </li>
            ))}
        </ul>
        <div className="app_navbar-menu lg:hidden flex rounded-full hover:bg-orange-light py-2 px-2 relative" >
            <HiMenuAlt4 className="w-4 h-4 hover:cursor-pointer text-orange-light hover:text-aqua-dark" onClick={toggleMenu} />
            {menuToggled && (
                <motion.div className="h-full w-2/3 fixed bottom-0 top-0 right-0 z-10 bg-aqua-dark" whileInView={{ x: [200, 0] }} transition={{ duration: 0.85, ease: 'easeOut' }}>
                    <HiX className="flex flex-1 w-8 h-8 ml-auto mt-4 mr-4 hover:cursor-pointer text-orange-light" onClick={toggleMenu} />
                    <ul className="flex justify-center align-start flex-col">
                        {['About', 'Work', 'Skills', 'Contact'].map((item) => (
                            <li className="my-4 mx-0 px-4 flex-col  justify-center list-none" key={`${item}`}>
                                <a className="text-gray-400 no-underline uppercase font-medium transition ease-in-out delay-75 hover:text-orange-light" onClick={toggleMenu}  href={`#${item}`}>
                                    {item}
                                </a>
                            </li>
                        ))}
                    </ul>
                </motion.div>
            )}
        </div>
    </nav>);
}


export default NavBar;